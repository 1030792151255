import firebase from 'firebase/app';
import 'firebase/auth';

import { isMobile } from '@/classes/Tools';

export default {

  state: {
    user: {},
    userEvents: {},
  },

  getters: {
    isLoggedIn: (state) => state.user?.id,
    getUser: (state) => state.user,
    getUserEvents: (state) => state.userEvents,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserEvents(state, events) {
      state.userEvents = events;
    },
    addUserEvents(state, event) {
      state.userEvents = { ...event };
    },
  },

  actions: {
    autoLogin({ dispatch, commit }) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user?.email) {
          dispatch('findUser', user);
        } else {
          commit('setUser', null);
        }
      });
    },
    login() {
      return new Promise((resolve) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.setCustomParameters({
              prompt: 'select_account',
            });
            const method = (p) => (!isMobile() ? firebase.auth().signInWithPopup(p) : firebase.auth().signInWithRedirect(p));
            method(provider).then(() => resolve());
            // firebase.auth().signInWithPopup(provider).then(() => resolve());
          });
      });
    },
    logout() {
      return new Promise((resolve) => {
        firebase.auth().signOut().then(() => resolve());
      });
    },
    findUser({ dispatch }, user) {
      firebase
        .firestore()
        .collection('players')
        .where('email', '==', user.email)
        .get()
        .then((snapshot) => {
          const userObject = {
            name: user.displayName,
            email: user.email,
            image: user.photoURL,
            gmailId: user.uid,
          };
          if (!snapshot.docs.length) {
            dispatch('createPlayer', userObject)
              .then(({ id }) => {
                dispatch('getUser', id);
              });
          } else if (!snapshot.docs[0].data().gmailId) {
            dispatch('updatePlayer', { id: snapshot.docs[0].id, player: userObject })
              .then(() => {
                dispatch('getUser', snapshot.docs[0].id);
              });
          } else {
            dispatch('getUser', snapshot.docs[0].id);
          }
        });
    },
    getUser({ commit, dispatch }, userId) {
      firebase
        .firestore()
        .collection('players')
        .doc(userId)
        .onSnapshot((response) => {
          commit('setUser', {
            id: response.id,
            ...response.data(),
          });
          dispatch('getUserEvents', response.data().events);
        });
    },
    getUserEvents({ commit }, events) {
      if (!events) {
        commit('setUserEvents', null);
      } else {
        const detailedEvents = {};
        Object.keys(events).forEach((eventId) => {
          firebase
            .firestore()
            .collection('events')
            .doc(eventId)
            .onSnapshot((snapshot) => {
              detailedEvents[eventId] = { id: eventId, ...snapshot.data() };
              commit('addUserEvents', detailedEvents);
            });
        });
      }
    },
  },

};
