import Config from '@/config';

/*
 * Output :
    {
      score: Number,
      nbVictory: Number,
      nbDefeat: Number,
      ratio: Number
    }
 *
 */
export function getPlayerStats(player, event, season = null) {
  const stats = {
    score: null,
    nbVictory: 0,
    nbDefeat: 0,
    ratio: 0,
  };
  if (player.games) {
    Object.keys(player.games).forEach((id) => {
      if (event?.games?.[id]) {
        const val = player.games[id];
        if (!season || (event.games[id] >= season.from && event.games[id] <= season.to)) {
          const weight = event.weighting ? val : 1;
          stats.score += weight * Config.points[val > 0 ? 'victory' : 'defeat'];
          stats[`nb${val > 0 ? 'Victory' : 'Defeat'}`] += 1;
        }
      }
    });
  }
  stats.ratio = parseInt((stats.nbVictory / (stats.nbVictory + stats.nbDefeat)) * 100, 10);
  return stats;
}

/*
 * Output :
    {
      id: String,
      created: Date,
      createdBy: String (id),
      events: Object {
        [id]: true,
      },
      games: Object {
        [id]: Number (ratio),
      },
      name: String,
      stats: Object,
    }
 *
 */
export function playerHydrator(playerObj, playerId, event) {
  return {
    ...playerObj,
    id: playerId,
    stats: getPlayerStats(playerObj, event),
  };
}
