<template>
  <y-sheet>

    <template #title>
      Créer un évènement
    </template>

    <form @submit.prevent="createNewEvent">

      <y-input v-model="newEventName">
        Nom de l'évènement*
      </y-input>

      <y-switch v-model="newEventWeighting">
        Activation de la pondération
        <template #legend>
          Lors de l'ajout d'un match, la différence de classement entre les
          joueurs des deux équipes sera prise en compte lors du calcul des points.
        </template>
      </y-switch>

      <y-switch v-model="newEventActivateSeasons">
        Activation des saisons
        <template #legend>
          Cet évènement sera découpé en plusieurs saisons, permettant ainsi de
          réinitialiser le classement régulièrement.
        </template>
      </y-switch>

      <div
        v-if="newEventActivateSeasons"
        class="grid gap-2 grid-cols-1 md:grid-cols-2">
        <div>
          <y-date-picker v-model="newEventSeasonStart">
            Début de la première saison
          </y-date-picker>
        </div>
        <div>
          <y-date-picker v-model="newEventSeasonEnd">
            Fin de la première saison
          </y-date-picker>
        </div>
      </div>

    </form>

    <template #footer>
      <button
        :class="{
          'text-sm text-white px-4 py-3 w-full rounded focus:outline-none uppercase transition': true,
          'bg-gray-600 cursor-not-allowed': !canSubmit,
          'bg-green-500 hover:bg-green-600': canSubmit,
        }"
        @click="createNewEvent">
        Créer
      </button>
    </template>

  </y-sheet>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { generateRandomId, getTimeStampStartOfTheDay, getTimeStampEndOfTheDay } from '@/classes/Tools';

import YSheet from '@/UI/YSheet.vue';
import YInput from '@/UI/YInput.vue';
import YSwitch from '@/UI/YSwitch.vue';
import YDatePicker from '@/UI/YDatePicker.vue';

import ConfirmModal from '@/modals/ConfirmModal.vue';

export default {

  components: {
    YSheet,
    YInput,
    YSwitch,
    YDatePicker,
  },

  props: {
    modalRef: {
      type: String,
      required: true,
    },
    playerId: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const internalInstance = getCurrentInstance();
    const { $emitter } = internalInstance.appContext.config.globalProperties;

    const store = useStore();

    const newEventName = ref('');
    const newEventWeighting = ref(true);
    const newEventActivateSeasons = ref(true);

    const start = Date.now();
    const end = new Date(start);
    end.setMonth(end.getMonth() + 6);

    const newEventSeasonStart = ref(start);
    const newEventSeasonEnd = ref(end * 1);

    const canSubmit = computed(() => newEventName.value && newEventName.value?.length >= 3);
    const getUser = computed(() => store.getters.getUser);

    const closeModal = () => {
      $emitter.emit('modal:close', props.modalRef);
    };

    const createNewEvent = () => {
      if (canSubmit.value) {
        $emitter.emit('modal:open', {
          component: ConfirmModal,
          props: {
            body: `
              Souhaitez-vous créer l'évènement <b>${newEventName.value}</b>
              ${props.playerId ? 'et l\'ajouter à vos évènements' : ''} ?
            `,
            onConfirm: () => {
              const event = {
                name: newEventName.value,
                weighting: newEventWeighting.value,
                seasonality: newEventActivateSeasons.value,
                createdBy: getUser.value.id,
              };
              if (newEventActivateSeasons.value) {
                event.seasons = {
                  [generateRandomId()]: {
                    from: getTimeStampStartOfTheDay(newEventSeasonStart.value),
                    to: getTimeStampEndOfTheDay(newEventSeasonEnd.value),
                    name: 'Saison 01',
                  },
                };
              }
              store
                .dispatch('createEvent', event)
                .then((response) => {
                  if (response?.id && props.playerId) {
                    store.dispatch('syncPlayerAndEvent', {
                      eventId: response.id,
                      playerId: props.playerId,
                    }).then(() => {
                      closeModal();
                    });
                  } else {
                    closeModal();
                  }
                });
            },
          },
        });
      }
    };

    return {
      newEventName,
      newEventWeighting,
      newEventActivateSeasons,
      newEventSeasonStart,
      newEventSeasonEnd,
      canSubmit,
      createNewEvent,
    };
  },

};
</script>
