import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomePage.vue'),
  },

  {
    path: '/account',
    name: 'User',
    redirect: { name: 'UserEvents' },
    component: () => import('@/views/UserPage.vue'),
    children: [
      {
        path: '/account/events',
        name: 'UserEvents',
        component: () => import('@/views/UserPageEvents.vue'),
      },
      {
        path: '/account/settings',
        name: 'UserSettings',
        component: () => import('@/views/UserPageSettings.vue'),
      },
    ],
  },

  {
    path: '/event/:id',
    name: 'Event',
    redirect: { name: 'EventRanking' },
    component: () => import('@/views/EventPage.vue'),
    meta: {
      back: { name: 'User' },
    },
    children: [
      {
        path: '/event/:id/ranking',
        name: 'EventRanking',
        component: () => import('@/views/EventPageRanking.vue'),
      },
      {
        path: '/event/:id/players',
        name: 'EventPlayers',
        component: () => import('@/views/EventPagePlayers.vue'),
      },
      {
        path: '/event/:id/games',
        name: 'EventGames',
        component: () => import('@/views/EventPageGames.vue'),
      },
      {
        path: '/event/:id/seasons',
        name: 'EventSeasons',
        component: () => import('@/views/EventPageSeasons.vue'),
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
