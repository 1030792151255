<template>
  <y-sheet>

    <template #title>
      Ajouter un match
    </template>

    <form @submit.prevent="createNewGame">
      <div class="grid grid-cols-1 gap-2 mb-4">
        <div>
          <y-date-picker v-model="date">
            Date
            <template v-if="getEvent.seasonality">
              <span v-if="relatedSeason?.name">
                (Ce match sera lié à la saison <b>"{{ relatedSeason.name }}"</b>)
              </span>
              <span
                class="text-red-500"
                v-else-if="!relatedSeason">
                (Ce match ne sera lié à aucune saison !)
              </span>
            </template>
          </y-date-picker>
        </div>
        <!-- <input
          class="w-full border px-4 py-2 rounded col-span-3"
          type="date"
          placeholder="Date"
          v-model="date" />
        <input
          class="w-full border px-4 py-2 rounded col-span-2"
          type="time"
          placeholder="heure"
          v-model="time" /> -->
      </div>

      <label class="text-xs font-light text-gray-400 block mb-1">
        Gagnants*
      </label>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
        <span
          class="relative"
          v-for="i in nbPlayersByTeam"
          :key="i">
          <select
            class="w-full border px-4 py-2 rounded appearance-none"
            v-model="winners[i - 1]">
            <option disabled selected value="">Nom</option>
            <option
              v-for="player in getEventPlayers"
              :key="player.id"
              :value="player.id">
              {{ player.name }}
            </option>
          </select>
          <span
            v-if="getRankedById(winners[i - 1])"
            :class="[
              'absolute right-4 top-0 bottom-0',
              'flex justify-center items-center',
              'text-sm font-semibold pointer-events-none',
              `text-${getRankingColor(getRankedById(winners[i - 1]) - 1)}`,
            ]">
            #{{ getRankedById(winners[i - 1]) }}
          </span>
        </span>
      </div>

      <label class="text-xs font-light text-gray-400 block mb-1">
        Perdants*
      </label>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <span
          class="relative"
          v-for="i in nbPlayersByTeam"
          :key="i">
          <select
            class="w-full border px-4 py-2 rounded appearance-none"
            v-model="loosers[i - 1]">
            <option disabled selected value="">Nom</option>
            <option
              v-for="player in getEventPlayers"
              :key="player.id"
              :value="player.id">
              {{ player.name }}
            </option>
          </select>
          <span
            v-if="getRankedById(loosers[i - 1])"
            :class="[
              'absolute right-4 top-0 bottom-0',
              'flex justify-center items-center',
              'text-sm font-semibold pointer-events-none',
              `text-${getRankingColor(getRankedById(loosers[i - 1]) - 1)}`,
            ]">
            #{{ getRankedById(loosers[i - 1]) }}
          </span>
        </span>
      </div>
    </form>

    <template #footer>
      <button
        :class="{
          'text-sm text-white px-4 py-3 w-full rounded focus:outline-none uppercase transition': true,
          'bg-gray-600 cursor-not-allowed': !canSubmit,
          'bg-green-500 hover:bg-green-600': canSubmit,
        }"
        @click="createNewGame">
        Créer
      </button>
      <button
        class="bg-blue-500 hover:bg-blue-600 text-sm ml-2 text-white px-4 py-3 rounded focus:outline-none uppercase transition"
        @click="openPlayerCreator">
        <y-icon-user-add class="h-5" />
      </button>
    </template>

  </y-sheet>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import {
  convertTimeStampToTime,
  // convertDateToTimeStamp,
  convertArrayToFirebaseObj,
  sortFirebaseObjectBy,
  sortArrayBy,
  getRankingColor,
} from '@/classes/Tools';
import { getPlayerStats } from '@/classes/Hydrators';
import Config from '@/config';

import YSheet from '@/UI/YSheet.vue';
import YIconUserAdd from '@/UI/icons/YIconUserAdd.vue';
import YDatePicker from '@/UI/YDatePicker.vue';
import PlayerCreatorModal from '@/modals/PlayerCreatorModal.vue';
import ConfirmModal from '@/modals/ConfirmModal.vue';

export default {

  components: {
    YSheet,
    YDatePicker,
    YIconUserAdd,
  },

  props: {
    modalRef: {
      type: String,
      required: true,
    },
    eventId: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const store = useStore();
    const internalInstance = getCurrentInstance();
    const { $emitter, $filters } = internalInstance.appContext.config.globalProperties;

    const date = ref(Date.now());
    const time = ref(convertTimeStampToTime(Date.now()));

    const getEvent = computed(() => store.getters.getEvent);

    const relatedSeason = computed(() => {
      if (getEvent.value.seasonality) {
        const seasonId = Object.keys(getEvent.value.seasons).filter((id) => {
          const s = getEvent.value.seasons[id];
          return s.from <= date.value && s.to >= date.value;
        })?.[0];
        return seasonId ? getEvent.value.seasons[seasonId] : null;
      }
      return null;
    });

    const nbPlayersByTeam = 2;
    const getUser = computed(() => store.getters.getUser);
    const getEventPlayers = computed(() => {
      const players = sortFirebaseObjectBy(store.getters.getEventPlayers, 'stats.score').reverse();
      if (relatedSeason.value) {
        players.forEach((player) => {
          // eslint-disable-next-line no-param-reassign
          player.stats = getPlayerStats(player, getEvent.value, relatedSeason.value);
        });
      }
      let ranking = 1;
      players.forEach((player) => {
        if (player.stats?.score) {
          // eslint-disable-next-line no-param-reassign
          player.ranking = ranking;
          ranking += 1;
        }
      });
      return sortArrayBy(players, 'name');
    });
    const getRankedById = (id) => getEventPlayers.value.filter((e) => e.id === id)?.[0]?.ranking;

    const winners = ref(Array(nbPlayersByTeam).fill(''));
    const loosers = ref(Array(nbPlayersByTeam).fill(''));

    const canSubmit = computed(() => (
      winners.value.filter((e) => e !== '').length === nbPlayersByTeam
      && loosers.value.filter((e) => e !== '').length === nbPlayersByTeam
    ));
    const closeModal = () => {
      $emitter.emit('modal:close', props.modalRef);
    };

    const calcWeight = computed(() => {
      if (!canSubmit.value) return 1;
      let winnersWeight = 0;
      let loosersWeight = 0;
      const totalRankedPlayers = getEventPlayers.value.filter((e) => e.stats?.score).length || 1;
      winners.value.forEach((e) => {
        winnersWeight += getRankedById(e) || (totalRankedPlayers / 2);
      });
      loosers.value.forEach((e) => {
        loosersWeight += getRankedById(e) || (totalRankedPlayers / 2);
      });
      const diff = (loosersWeight / loosers.value.length) - (winnersWeight / winners.value.length);
      const ratio = (-(diff / totalRankedPlayers)) + 1;
      return parseFloat(ratio.toFixed(2));
    });

    const createNewGame = () => {
      if (canSubmit.value) {
        const game = {
          // date: convertDateToTimeStamp(date.value, time.value),
          date: date.value,
          createdBy: getUser.value.id,
          winners: convertArrayToFirebaseObj(winners.value, 1 * calcWeight.value),
          loosers: convertArrayToFirebaseObj(loosers.value, -1 * calcWeight.value),
        };

        $emitter.emit('modal:open', {
          component: ConfirmModal,
          props: {
            body: `
              ${getEvent.value.weighting ? `Ce match a une pondération de <b>${(calcWeight.value) * 100}%</b>.<br>` : ''}
              Cela ajoutera <span class="text-xs px-2 py-0.5 text-white rounded-full bg-green-500 whitespace-nowrap">${$filters.pluralize(Config.points.victory * (getEvent.value.weighting ? calcWeight.value : 1), 'pt')}</span> aux gagnants,
              et retirera <span class="text-xs px-2 py-0.5 text-white rounded-full bg-red-500 whitespace-nowrap">${$filters.pluralize(Config.points.defeat * (getEvent.value.weighting ? calcWeight.value : 1), 'pt')}</span> aux perdants.<br>
            `,
            onConfirm: () => {
              store.dispatch('createGame', game)
                .then((response) => {
                  if (response?.id && props.eventId) {
                    store.dispatch('syncGames', {
                      eventId: props.eventId,
                      gameId: response.id,
                      game,
                      playersId: {
                        ...game.winners,
                        ...game.loosers,
                      },
                    }).then(() => {
                      closeModal();
                    });
                  } else {
                    closeModal();
                  }
                });
            },
          },
        });
      }
    };

    const openPlayerCreator = () => {
      $emitter.emit('modal:open', {
        component: PlayerCreatorModal,
        props: { eventId: props.eventId },
      });
    };

    return {
      nbPlayersByTeam,
      getEvent,
      getEventPlayers,
      date,
      time,
      relatedSeason,
      winners,
      loosers,
      canSubmit,
      createNewGame,
      openPlayerCreator,
      getRankedById,
      getRankingColor,
    };
  },

};
</script>
