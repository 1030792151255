<template>
  <transition
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
    appear>
    <div
      v-if="visibility"
      @click="closeSelf"
      class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
  </transition>

  <transition
    enter-from-class="translate-y-full md:translate-y-0 md:scale-125 opacity-0"
    leave-to-class="translate-y-full md:translate-y-0 md:scale-125 opacity-0"
    appear
    @after-leave="afterLeave">
    <div
      :class="[
        'fixed left-0 right-0 bottom-0 md:top-0',
        'flex items-center justify-center',
        'transform transition pointer-events-none',
      ]"
      v-if="visibility">

      <div class="relative w-full rounded-t max-w-md transform transition pointer-events-auto">

        <button
          :class="[
            'absolute right-4 bottom-full p-2 transform translate-y-1/2',
            'bg-white shadow md:hover:bg-gray-50',
            'rounded-full focus:outline-none transition-colors',
          ]"
          @click="closeSelf">
          <y-icon-x class="h-4" />
        </button>

        <slot />

      </div>
    </div>
  </transition>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import YIconX from '@/UI/icons/YIconX.vue';

export default {

  components: {
    YIconX,
  },

  props: {
    modalRef: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { $emitter } = getCurrentInstance().appContext.config.globalProperties;

    const visibility = ref(true);
    const closeSelf = () => {
      visibility.value = false;
    };
    const afterLeave = () => {
      $emitter.emit('modal:delete', props.modalRef);
    };
    $emitter.on('modal:close', (modalRef) => {
      if (modalRef === props.modalRef) closeSelf();
    });

    return {
      visibility,
      closeSelf,
      afterLeave,
    };
  },

};
</script>
