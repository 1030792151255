import firebase from 'firebase/app';
import 'firebase/firestore';

export default {

  state: () => ({}),

  getters: {},

  mutations: {},

  actions: {
    createPlayer(context, player = {}) {
      return new Promise((resolve) => {
        firebase
          .firestore()
          .collection('players')
          .add({
            created: Date.now(),
            ...player,
          })
          .then((response) => resolve(response));
      });
    },
    updatePlayer(context, { id, player }) {
      return new Promise((resolve) => {
        firebase
          .firestore()
          .collection('players')
          .doc(id)
          .update(player)
          .then((response) => resolve(response));
      });
    },
    getPlayerByEmail(context, email) {
      return new Promise((resolve, reject) => {
        if (email) {
          firebase
            .firestore()
            .collection('players')
            .where('email', '==', email)
            .get()
            .then((response) => {
              if (response.docs?.[0]?.id) {
                resolve(response.docs[0]);
              }
              reject();
            })
            .catch(() => reject());
        } else {
          reject();
        }
      });
    },
  },

};
