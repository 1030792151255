import { createStore } from 'vuex';

import firebase from 'firebase/app';
import config from '@/config';

firebase.initializeApp(config.database.dev);

/* eslint-disable import/first */
import User from '@/store/UserStore';
import Events from '@/store/EventsStore';
import Players from '@/store/PlayersStore';
import Games from '@/store/GamesStore';
import Sync from '@/store/SyncStore';

export default createStore({

  modules: {
    User,
    Events,
    Players,
    Games,
    Sync,
  },

});
