<template>
  <y-sheet>

    <template #title>
      <p class="flex items-center justify-center capitalize relative">
        <button
          class="absolute left-0 bg-white md:hover:bg-gray-50 transition shadow p-2 rounded-full focus:outline-none"
          @click="monthOffset -= 1">
          <y-icon-chevron-left class="h-4" />
        </button>
        <button
          class="absolute left-10 bg-white shadow p-2 rounded-full focus:outline-none"
          @click="monthOffset += 1">
          <y-icon-chevron-right class="h-4" />
        </button>
        {{ months[month] }} {{ year }}
      </p>
    </template>

    <ul class="grid gap-1 grid-cols-7 text-center pb-4">
      <li
        class="text-xs text-gray-400"
        v-for="day in days"
        :key="day">
        {{ day }}
      </li>
    </ul>

    <ul class="grid gap-1 grid-cols-7 text-center">
      <li
        v-for="(item, index) in calendar"
        :key="index"
        :class="{
          'relative pb-full rounded hover:bg-green-500 hover:text-white transition cursor-pointer group': true,
          'text-gray-400 font-light': item.siblingMonth,
          'bg-gray-100': item.isDefaultDate,
        }"
        @click="onSelect(item.timestamp)">
        <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {{ item.day }}
        </span>
        <span
          :class="{
            'absolute bottom-1/4 left-1/2 transform -translate-x-1/2 translate-y-1/2 h-1.5 w-1.5 rounded transition': true,
            'bg-blue-500 group-hover:bg-white': item.isToday,
          }" />
      </li>
    </ul>

  </y-sheet>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { Calendar } from 'calendar-base';
import { convertTimeStampToShortFormatedDate, months } from '@/classes/Tools';

import YSheet from '@/UI/YSheet.vue';
import YIconChevronLeft from '@/UI/icons/YIconChevronLeft.vue';
import YIconChevronRight from '@/UI/icons/YIconChevronRight.vue';

export default {

  components: {
    YSheet,
    YIconChevronLeft,
    YIconChevronRight,
  },

  props: {
    modalRef: {
      type: String,
      required: true,
    },
    date: {
      type: Number,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const internalInstance = getCurrentInstance();
    const { $emitter } = internalInstance.appContext.config.globalProperties;

    const days = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'];
    const cal = new Calendar({
      siblingMonths: true,
      weekStart: 1,
    });

    const defaultDate = new Date(props.date);
    const today = new Date();

    const monthOffset = ref(0);
    const date = computed(() => {
      const d = new Date(props.date);
      d.setDate(1);
      d.setMonth(d.getMonth() + monthOffset.value);
      return d;
    });
    const year = computed(() => date.value.getFullYear());
    const month = computed(() => date.value.getMonth());

    const calendar = computed(() => {
      const c = cal.getCalendar(year.value, month.value);

      c.forEach((d) => {
        /* eslint-disable no-param-reassign */
        d.timestamp = new Date(d.year, d.month, d.day) * 1;
        d.isDefaultDate = convertTimeStampToShortFormatedDate(d.timestamp) === convertTimeStampToShortFormatedDate(defaultDate);
        d.isToday = convertTimeStampToShortFormatedDate(d.timestamp) === convertTimeStampToShortFormatedDate(today);
      });
      return c;
    });

    const onSelect = (timestamp) => {
      if (typeof props.onSubmit === 'function') {
        props.onSubmit(timestamp);
        $emitter.emit('modal:close', props.modalRef);
      }
    };

    return {
      days,
      months,
      month,
      monthOffset,
      year,
      calendar,
      onSelect,
    };
  },

};
</script>
