<template>
  <div class="absolute z-40">
    <component
      v-for="modal in mountedModals"
      :key="modal.modalRef"
      :is="modal.component" />
  </div>
</template>

<script>
import {
  h,
  shallowRef,
  triggerRef,
  getCurrentInstance,
} from 'vue';

import findIndex from 'lodash/findIndex';
import ModalWrapper from '@/components/ModalWrapper.vue';

export default {
  setup() {
    const { $emitter } = getCurrentInstance().appContext.config.globalProperties;
    const mountedModals = shallowRef([]);

    $emitter.on('modal:open', ({ component, props }) => {
      const modalRef = `modal-${Date.now()}`;
      mountedModals.value.push({
        modalRef,
        component: {
          render: () => h(ModalWrapper, { modalRef }, () => h(component, { ...props, modalRef })),
        },
      });
      triggerRef(mountedModals);
    });

    $emitter.on('modal:delete', (modalRef) => {
      const index = findIndex(mountedModals.value, (m) => m.modalRef === modalRef);
      mountedModals.value.splice(index, 1);
      triggerRef(mountedModals);
    });

    return { mountedModals };
  },
};
</script>
