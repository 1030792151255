import { createApp } from 'vue';
import mitt from 'mitt';

import 'tailwindcss/tailwind.css';
import 'chartist/dist/chartist.css';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import { convertTimeStampToFormatedDate } from '@/classes/Tools';

const app = createApp(App)
  .use(store)
  .use(router);

app.config.globalProperties.$filters = {
  pluralize(value, singular, plural) {
    return `${String(value).replace('.', ',')} ${(parseFloat(Math.abs(value)) > 1 ? (plural || `${singular}s`) : singular)}`;
  },
  convertTimeStampToFormatedDate,
};
app.config.globalProperties.$emitter = mitt();

app.mount('#app');
