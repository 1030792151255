export default {

  database: {
    dev: {
      apiKey: 'AIzaSyDBlTg5txspaNt81ODCJUA-xVWQ4n5FH3Q',
      authDomain: 'fannycity-v3.firebaseapp.com',
      databaseURL: 'https://fannycity-v3-default-rtdb.europe-west1.firebasedatabase.app',
      projectId: 'fannycity-v3',
      storageBucket: 'fannycity-v3.appspot.com',
      messagingSenderId: '1094109332514',
      appId: '1:1094109332514:web:a36d76560bb51c0380767b',
    },
  },

  points: {
    victory: 2,
    defeat: 1,
  },

};
