<template>
  <span
    v-if="player"
    :class="[
      'inline-flex items-center justify-center text-white bg-cover bg-center rounded-full bg-gray-200 leading-0',
      `h-${size} w-${size}`,
      { 'text-opacity-0': player?.image },
    ]"
    :style="{
      backgroundImage: `url(${player?.image})`,
      backgroundColor: playerColor,
    }">
    {{ player?.name?.substr(0, 1) }}
  </span>
</template>

<script>
import { convertToColor } from '@/classes/Tools';

export default {

  props: {
    player: {
      type: Object,
      required: false,
    },
    size: {
      type: [String, Number],
      required: false,
      default: 8,
    },
  },

  setup(props) {
    const playerColor = convertToColor(props.player?.id + props.player?.name);
    return { playerColor };
  },

};
</script>
