<template>
  <div
    class="flex cursor-pointer select-none mb-4 last:mb-0"
    @click="$emit('update:modelValue', !modelValue)">

    <div
      :class="[
        'relative h-7 w-12 min-w-12 rounded-full mr-2 transition',
        `bg-${modelValue ? 'green-500' : 'gray-300'}`,
      ]">
      <span
        :class="[
          'absolute top-1 left-1 h-5 w-5 bg-white block rounded-full transform transition',
          `translate-x-${modelValue ? 0 : 5}`,
        ]" />
    </div>

    <div>
      <p class="text-sm">
        <slot />
      </p>
      <p class="text-xs font-light leading-4 text-gray-400">
        <slot name="legend" />
      </p>
    </div>

  </div>
</template>

<script>
export default {

  emits: [
    'update:modelValue',
  ],

  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

};
</script>
