import firebase from 'firebase/app';
import 'firebase/firestore';

export default {

  state: () => ({}),

  getters: {},

  mutations: {},

  actions: {
    createGame(context, game = {}) {
      return new Promise((resolve) => {
        firebase
          .firestore()
          .collection('games')
          .add({
            created: Date.now(),
            ...game,
          })
          .then((response) => resolve(response));
      });
    },
  },

};
