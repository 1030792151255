<template>
  <div class="flex relative justify-between items-center p-2 bg-black text-white bg-opacity-20 mb-8 z-40">

    <span class="flex items-center">
      <transition
        enter-from-class="opacity-0"
        leave-to-class="opacity-0">
        <router-link
          class="inline-block p-2 bg-black bg-opacity-25 hover:bg-opacity-50 transition text-white rounded focus:outline-none transition"
          v-if="$route.matched?.[0]?.meta?.back"
          :to="$route.matched?.[0]?.meta?.back">
          <y-icon-chevron-left class="h-5" />
        </router-link>
      </transition>
    </span>

    <img
      class="absolute left-1/2 top-4 h-16 transform -translate-x-1/2"
      :src="LOGO" />

    <span class="flex items-center">
      <button
        class="p-2 bg-black bg-opacity-25 hover:bg-opacity-50 transition text-white rounded focus:outline-none"
        v-if="!isLoggedIn"
        @click="login">
        <y-icon-user class="h-5" />
      </button>

      <template v-else>
        <router-link
          class="mr-2 flex items-center"
          v-if="getUser.image"
          :to="{ name: 'User' }">
          <span class="mr-2 text-xs hidden md:inline-block">
            <span class="opacity-50">
              Bonjour
            </span>
            {{ getUser.name }}
          </span>
          <y-avatar
            size="9"
            :player="getUser" />
        </router-link>
        <button
          class="p-2 bg-black bg-opacity-25 hover:bg-opacity-50 text-white rounded focus:outline-none"
          @click="logout">
          <y-icon-logout class="h-5" />
        </button>
      </template>
    </span>

  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import YAvatar from '@/UI/YAvatar.vue';
import YIconLogout from '@/UI/icons/YIconLogout.vue';
import YIconUser from '@/UI/icons/YIconUser.vue';
import YIconChevronLeft from '@/UI/icons/YIconChevronLeft.vue';

import LOGO from '@/assets/fannycity@2x.png';

export default {

  components: {
    YAvatar,
    YIconLogout,
    YIconUser,
    YIconChevronLeft,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const login = () => store.dispatch('login').then(() => {
      router.push({ name: 'User' });
    });
    const logout = () => store.dispatch('logout').then(() => {
      router.push({ name: 'Home' });
    });

    const getUser = computed(() => store.getters.getUser);
    const isLoggedIn = computed(() => store.getters.isLoggedIn);

    store.dispatch('autoLogin');

    return {
      login,
      logout,
      getUser,
      isLoggedIn,
      LOGO,
    };
  },

};
</script>
