<template>
  <y-sheet>

    <template #title>
      Ajouter un joueur
    </template>

    <form @submit.prevent="createNewPlayer">
      <label class="text-xs font-light text-gray-400 block mb-1">
        Nom*
      </label>
      <input
        class="w-full border px-4 py-2 rounded mb-4"
        v-model="newPlayerName"
        placeholder="Gaston" />

      <label class="text-xs font-light text-gray-400 block mb-1">
        Email
      </label>
      <input
        class="w-full border px-4 py-2 rounded"
        v-model="newPlayerEmail"
        placeholder="gaston@lagaffe.net" />
      <p class="mt-2 text-xs font-light text-gray-400">
        En renseignant son email, cela nous permettra d'inviter automatiquement le joueur à rejoindre cet évènement.
      </p>
    </form>

    <template #footer>
      <button
         :class="{
          'text-sm text-white px-4 py-3 w-full rounded focus:outline-none uppercase transition': true,
          'bg-gray-600 cursor-not-allowed': !canSubmit,
          'bg-green-500 hover:bg-green-600': canSubmit,
        }"
        @click="createNewPlayer">
        Ajouter
      </button>
    </template>

  </y-sheet>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

import YSheet from '@/UI/YSheet.vue';

export default {

  components: {
    YSheet,
  },

  props: {
    modalRef: {
      type: String,
      required: true,
    },
    eventId: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const store = useStore();
    const { $emitter } = getCurrentInstance().appContext.config.globalProperties;
    const getUser = computed(() => store.getters.getUser);

    const newPlayerName = ref('');
    const newPlayerEmail = ref('');
    const canSubmit = computed(() => newPlayerName.value && newPlayerName.value?.length >= 3);

    const closeModal = () => {
      $emitter.emit('modal:close', props.modalRef);
    };

    const sync = (playerId, eventId) => {
      store.dispatch('syncPlayerAndEvent', {
        playerId,
        eventId,
      }).then(() => {
        closeModal();
      });
    };

    const createNewPlayer = () => {
      if (canSubmit.value) {
        const player = {
          email: newPlayerEmail.value,
          name: newPlayerName.value,
          createdBy: getUser.value.id,
        };
        store
          .dispatch('getPlayerByEmail', player.email)
          .then((response) => {
            sync(response.id, props.eventId);
          })
          .catch(() => {
            store
              .dispatch('createPlayer', player)
              .then((response) => {
                if (response?.id && props.eventId) {
                  sync(response.id, props.eventId);
                } else {
                  closeModal();
                }
              });
          });
      }
    };

    return {
      newPlayerName,
      newPlayerEmail,
      canSubmit,
      createNewPlayer,
    };
  },

};
</script>
