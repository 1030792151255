<template>

  <top-bar />

  <div
    :class="[
      'container font-body pb-16',
      'mx-auto px-4 sm:px-12 py-4',
    ]">
    <div class="relative overflow-hidden max-w-2xl m-auto">
      <router-view v-slot="{ Component }">
        <transition
          enter-from-class="-translate-y-12 opacity-0"
          leave-active-class="absolute w-full top-0"
          leave-to-class="translate-y-12 opacity-0">
          <div
            class="transform transition-all duration-300"
            :key="$route.matched?.[0]?.name">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
    </div>
  </div>

  <fab-manager />
  <modals-manager />

</template>

<script>
import TopBar from '@/components/TopBar.vue';

import ModalsManager from '@/components/ModalsManager.vue';
import FabManager from '@/components/FabManager.vue';

export default {

  components: {
    TopBar,
    ModalsManager,
    FabManager,
  },

};
</script>
