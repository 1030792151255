<template>
  <p class="mb-4 last:mb-0">
    <y-label>
      <slot />
    </y-label>
    <span
      class="w-full border px-4 py-2 rounded cursor-pointer block truncate"
      @click="openModal">
      {{ readableDate }}&nbsp;
    </span>
  </p>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import { convertTimeStampToShortFormatedDate } from '@/classes/Tools';

import YLabel from '@/UI/YLabel.vue';
import DatePickerModal from '@/modals/DatePickerModal.vue';

export default {

  components: {
    YLabel,
  },

  emits: [
    'update:modelValue',
  ],

  props: {
    modelValue: {
      type: Number,
      required: false,
      default: Date.now(),
    },
  },

  setup(props, { emit }) {
    const internalInstance = getCurrentInstance();
    const { $emitter } = internalInstance.appContext.config.globalProperties;

    const readableDate = computed(() => convertTimeStampToShortFormatedDate(props.modelValue));

    const openModal = () => {
      $emitter.emit('modal:open', {
        component: DatePickerModal,
        props: {
          date: props.modelValue,
          onSubmit: (val) => {
            emit('update:modelValue', val);
          },
        },
      });
    };

    return { openModal, readableDate };
  },

};
</script>
