<template>
  <y-sheet>

    <template #title>
      Rejoindre un évènement
    </template>

    <form @submit.prevent="findEvent">
      <label class="text-xs font-light text-gray-400 block mb-1">
        Code*
      </label>
      <input
        :class="{
          'w-full border px-4 py-2 rounded text-lg uppercase': true,
          'border-red-500 text-red-500': errorMessage,
        }"
        v-model="eventCode"
        placeholder="••••••••"
        @input="errorMessage = ''" />
      <p
        class="text-xs text-red-500 mt-2"
        v-if="errorMessage">
        {{ errorMessage }}
      </p>
      <p class="mt-2 text-xs font-light text-gray-400">
        Vous pouvez rejoindre à tout moment un évènement en rentrant son code ci-dessus. Pour connaitre ce code, il vous faut le demander à l'organisateur.
      </p>
    </form>

    <template #footer>
      <button
        :class="{
          'text-sm text-white px-4 py-3 w-full rounded focus:outline-none uppercase transition': true,
          'bg-gray-600 cursor-not-allowed': !canSubmit,
          'bg-green-500 hover:bg-green-600': canSubmit,
        }"
        @click="findEvent">
        Rejoindre
      </button>
    </template>

  </y-sheet>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

import YSheet from '@/UI/YSheet.vue';

export default {

  components: {
    YSheet,
  },

  props: {
    modalRef: {
      type: String,
      required: true,
    },
    playerId: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const internalInstance = getCurrentInstance();
    const { $emitter } = internalInstance.appContext.config.globalProperties;

    const store = useStore();
    const eventCode = ref('');
    const errorMessage = ref('');
    const canSubmit = computed(() => eventCode.value && eventCode.value?.length === 8);
    const findEvent = () => {
      if (canSubmit.value) {
        errorMessage.value = '';
        store
          .dispatch('findEventByCode', eventCode.value)
          .then((response) => {
            const eventId = response?.id;
            if (eventId && props.playerId) {
              store.dispatch('syncPlayerAndEvent', {
                eventId: response.id,
                playerId: props.playerId,
              }).then(() => {
                $emitter.emit('modal:close', props.modalRef);
              });
            } else {
              errorMessage.value = 'Code invalide';
            }
          })
          .catch(() => {
            errorMessage.value = 'Code invalide';
          });
      }
    };

    return {
      eventCode,
      canSubmit,
      findEvent,
      errorMessage,
    };
  },

};
</script>
