import firebase from 'firebase/app';
import 'firebase/firestore';

import { generateRandomId } from '@/classes/Tools';
import { playerHydrator } from '@/classes/Hydrators';

export default {

  state: () => ({
    event: {},
    eventPlayers: {},
    eventGames: {},
  }),

  getters: {
    getEvent: (state) => state.event,
    getEventPlayers: (state) => state.eventPlayers,
    getEventPlayersById: (state) => (id) => state.eventPlayers[id],
    getEventGames: (state) => state.eventGames,
  },

  mutations: {
    setEvent(state, event) {
      state.event = event;
    },
    setEventPlayers(state, players) {
      state.eventPlayers = players;
    },
    addEventPlayers(state, player) {
      state.eventPlayers = { ...player };
    },
    setEventGames(state, games) {
      state.eventGames = games;
    },
    addEventGames(state, game) {
      state.eventGames = { ...game };
    },
  },

  actions: {
    createEvent(context, event = {}) {
      return new Promise((resolve) => {
        firebase
          .firestore()
          .collection('events')
          .add({
            created: Date.now(),
            code: generateRandomId(),
            ...event,
          })
          .then((response) => resolve(response));
      });
    },
    getEventById({ dispatch, commit }, eventId) {
      return new Promise((resolve) => {
        firebase
          .firestore()
          .collection('events')
          .doc(eventId)
          .onSnapshot((response) => {
            commit('setEvent', {
              id: response.id,
              ...response.data(),
            });
            dispatch('getEventPlayers', response.data()?.players);
            dispatch('getEventGames', response.data()?.games);
            resolve(response);
          });
      });
    },
    getEventPlayers({ commit, getters }, players) {
      if (!players) {
        commit('setEventPlayers', null);
      } else {
        const detailedPlayers = {};
        Object.keys(players).forEach((playerId) => {
          firebase
            .firestore()
            .collection('players')
            .doc(playerId)
            .onSnapshot((snapshot) => {
              detailedPlayers[playerId] = playerHydrator(snapshot.data(), playerId, getters.getEvent);
              commit('addEventPlayers', detailedPlayers);
            });
        });
      }
    },
    findEventByCode(context, code) {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection('events')
          .where('code', '==', code)
          .get()
          .then((response) => {
            if (response?.docs?.[0]?.id) {
              resolve({
                id: response.docs.[0].id,
                ...response.docs.[0].data(),
              });
            } else {
              reject(new Error('Event not found'));
            }
          })
          .catch(() => {
            reject(new Error('Event not found'));
          });
      });
    },
    getEventGames({ commit }, games) {
      if (!games) {
        commit('setEventGames', null);
      } else {
        const detailedGames = {};
        Object.keys(games).forEach((gameId) => {
          firebase
            .firestore()
            .collection('games')
            .doc(gameId)
            .onSnapshot((snapshot) => {
              detailedGames[gameId] = { id: gameId, ...snapshot.data() };
              commit('addEventGames', detailedGames);
            });
        });
      }
    },
    createSeasonForEvent(context, { season, eventId }) {
      return new Promise((resolve) => {
        const seasonId = generateRandomId();
        firebase
          .firestore()
          .collection('events')
          .doc(eventId)
          .update({
            [`seasons.${seasonId}`]: {
              created: Date.now(),
              ...season,
            },
          })
          .then((response) => resolve(response));
      });
    },
    updateSeasonForEvent(context, { season, seasonId, eventId }) {
      return new Promise((resolve) => {
        firebase
          .firestore()
          .collection('events')
          .doc(eventId)
          .update({
            [`seasons.${seasonId}`]: {
              lastUpdate: Date.now(),
              ...season,
            },
          })
          .then((response) => resolve(response));
      });
    },
  },

};
