<template>

  <div class="overflow-y-auto max-h-sheet rounded-t md:rounded">
    <!-- Head -->
    <p
      v-if="$slots.title"
      class="p-4 font-semibold bg-gray-50">
      <slot name="title" />
    </p>
    <!-- Body -->
    <div class="p-4 bg-white">
      <slot />
    </div>
  </div>

  <!-- Footer -->
  <div
    v-if="$slots.footer"
    class="flex p-4 md:p-2 border-t md:border-none bg-gray-50 md:bg-transparent">
    <slot name="footer" />
  </div>

</template>
