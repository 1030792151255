<template>

  <transition
    appear
    enter-from-class="scale-0"
    leave-to-class="scale-0">
    <button
      v-if="actions.length"
      class="fixed z-30 bottom-4 right-4 p-3 bg-blue-500 hover:bg-blue-600 rounded-full text-white shadow-lg focus:outline-none transition transform"
      @click="showAvailableActions = !showAvailableActions">
      <y-icon-add
        :class="{
          'h-10 transform transition-transform': true,
          'rotate-45': showAvailableActions,
        }" />
    </button>
  </transition>

  <transition
    enter-from-class="opacity-0"
    leave-to-class="opacity-0">
    <div
      class="fixed z-20 inset-0 bg-black bg-opacity-75 transition-opacity"
      v-if="showAvailableActions"
      @click="showAvailableActions = false" />
  </transition>

  <transition
    enter-from-class="opacity-0 translate-y-2"
    leave-to-class="opacity-0 translate-y-2">
    <ul
      class="fixed z-30 bottom-20 right-8 -mr-0.5 transform transition"
      v-if="showAvailableActions">
      <li
        v-for="(action, index) in actions"
        :key="index">
        <button
          class="relative p-2 bg-blue-300 hover:bg-blue-400 transition text-white rounded-full mb-2 shadow focus:outline-none"
          @click="() => {
            action.action();
            showAvailableActions = false;
          }">
          <component
            class="h-5"
            :is="action.icon" />
          <span class="absolute right-full mr-2 bg-black bg-opacity-50 py-1 px-2 rounded text-white top-1/2 transform -translate-y-1/2 whitespace-nowrap text-xs pointer-events-none">
            {{ action.label }}
          </span>
        </button>
      </li>
    </ul>
  </transition>

</template>

<script>
import { ref } from 'vue';

import YIconAdd from '@/UI/icons/YIconAdd.vue';

export default {

  components: {
    YIconAdd,
  },

  props: {
    actions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const showAvailableActions = ref(false);
    return { showAvailableActions };
  },

};
</script>
