<template>
  <y-fab :actions="fabActions" />
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import YFab from '@/UI/YFab.vue';
import YIconDocumentAdd from '@/UI/icons/YIconDocumentAdd.vue';
import YIconUserAdd from '@/UI/icons/YIconUserAdd.vue';
import YIconMail from '@/UI/icons/YIconMail.vue';
import YIconPencil from '@/UI/icons/YIconPencil.vue';
import YIconDocumentSearch from '@/UI/icons/YIconDocumentSearch.vue';
import YIconCalendar from '@/UI/icons/YIconCalendar.vue';

import PlayerCreatorModal from '@/modals/PlayerCreatorModal.vue';
import GameCreatorModal from '@/modals/GameCreatorModal.vue';
import EventFinderModal from '@/modals/EventFinderModal.vue';
import EventCreatorModal from '@/modals/EventCreatorModal.vue';
import PlayerFinderModal from '@/modals/PlayerFinderModal.vue';
import SeasonCreatorModal from '@/modals/SeasonCreatorModal.vue';

export default {

  components: {
    YFab,
  },

  setup() {
    const internalInstance = getCurrentInstance();
    const { $emitter } = internalInstance.appContext.config.globalProperties;

    const store = useStore();
    const route = useRoute();
    const context = computed(() => route.matched?.[0]?.name);

    const fabActions = computed(() => {
      switch (context.value) {
        case 'Event':
          return [
            ...store.getters.getEvent?.seasonality ? [{
              label: 'Créer une saison',
              icon: YIconCalendar,
              action: () => $emitter.emit('modal:open', {
                component: SeasonCreatorModal,
                props: { eventId: route.params.id },
              }),
            }] : [],
            {
              label: 'Inviter un joueur',
              icon: YIconMail,
              action: () => $emitter.emit('modal:open', {
                component: PlayerFinderModal,
                props: { eventId: route.params.id },
              }),
            },
            {
              label: 'Ajouter un joueur',
              icon: YIconUserAdd,
              action: () => $emitter.emit('modal:open', {
                component: PlayerCreatorModal,
                props: { eventId: route.params.id },
              }),
            },
            {
              label: 'Ajouter un match',
              icon: YIconDocumentAdd,
              action: () => $emitter.emit('modal:open', {
                component: GameCreatorModal,
                props: { eventId: route.params.id },
              }),
            },
          ];
        case 'User':
          return [
            {
              label: 'Rejoindre un évènement',
              icon: YIconDocumentSearch,
              action: () => $emitter.emit('modal:open', {
                component: EventFinderModal,
                props: { playerId: store.getters.getUser?.id },
              }),
            },
            {
              label: 'Créer un évènement',
              icon: YIconPencil,
              action: () => $emitter.emit('modal:open', {
                component: EventCreatorModal,
                props: { playerId: store.getters.getUser?.id },
              }),
            },
          ];
        default:
          return [];
      }
    });

    return { fabActions };
  },
};
</script>
