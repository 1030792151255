<template>
  <y-sheet>

    <template #title>
      Inviter un joueur
    </template>

    <label class="text-xs font-light text-gray-400 block mb-1">
      Code
    </label>
    <input
      class="w-full border px-4 py-2 rounded"
      :value="getEventCode"
      disabled />
    <p class="mt-2 text-xs font-light text-gray-400">
      En transmettant ce code, n'importe quel joueur pourra rejoindre cet évènement.
    </p>

    <hr class="-mx-4 my-4" />

    <form @submit.prevent="invitNewPlayer">
      <label class="text-xs font-light text-gray-400 block mb-1">
        Email*
      </label>
      <input
        class="w-full border px-4 py-2 rounded"
        v-model="newPlayerEmail"
        placeholder="gaston@lagaffe.net" />
      <p class="mt-2 text-xs font-light text-gray-400">
        Si vous connaissez l'email du nouveau joueur, vous pouvez l'inviter directement à rejoindre cet évènement.
      </p>
    </form>

    <template #footer>
      <button
         :class="{
          'text-sm text-white px-4 py-3 w-full rounded focus:outline-none uppercase transition': true,
          'bg-gray-600 cursor-not-allowed': !canSubmit,
          'bg-green-500 hover:bg-green-600': canSubmit,
        }"
        @click="invitNewPlayer">
        Inviter
      </button>
    </template>

  </y-sheet>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

import YSheet from '@/UI/YSheet.vue';
import ConfirmModal from '@/modals/ConfirmModal.vue';

export default {

  components: {
    YSheet,
  },

  props: {
    modalRef: {
      type: String,
      required: true,
    },
    eventId: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const store = useStore();
    const { $emitter } = getCurrentInstance().appContext.config.globalProperties;

    const getUser = computed(() => store.getters.getUser);
    const getEventCode = computed(() => store.getters.getEvent?.code);

    const newPlayerEmail = ref('');
    const canSubmit = computed(() => newPlayerEmail.value);

    const closeModal = () => {
      $emitter.emit('modal:close', props.modalRef);
    };

    const sync = (playerId, eventId) => {
      store.dispatch('syncPlayerAndEvent', {
        playerId,
        eventId,
      }).then(() => {
        closeModal();
      });
    };

    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const invitNewPlayer = () => {
      if (canSubmit.value) {
        store
          .dispatch('getPlayerByEmail', newPlayerEmail.value)
          .then((response) => {
            sync(response.id, props.eventId);
            closeModal();
          })
          .catch(() => {
            $emitter.emit('modal:open', {
              component: ConfirmModal,
              props: {
                title: 'Que souhaitez-vous faire ?',
                body: `
                  Ce joueur n'est pas encore inscrit sur notre service.
                  Souhaitez-vous quand même l'ajouter dès maintenant à votre évènement ?
                `,
                onConfirm: () => {
                  const player = {
                    email: newPlayerEmail.value,
                    name: capitalize(newPlayerEmail.value.split('@')?.[0].split('.')?.[0]),
                    createdBy: getUser.value.id,
                  };
                  store
                    .dispatch('createPlayer', player)
                    .then((response) => {
                      if (response?.id && props.eventId) {
                        sync(response.id, props.eventId);
                      } else {
                        closeModal();
                      }
                    });
                },
              },
            });
          });
      }
    };

    return {
      newPlayerEmail,
      canSubmit,
      invitNewPlayer,
      getEventCode,
    };
  },

};
</script>
