import firebase from 'firebase/app';
import 'firebase/firestore';

export default {

  actions: {
    syncPlayerAndEvent(context, { playerId, eventId }) {
      return new Promise((resolve) => {
        const db = firebase.firestore();
        const batch = db.batch();

        batch.update(db.collection('events').doc(eventId), {
          [`players.${playerId}`]: true,
        });
        batch.update(db.collection('players').doc(playerId), {
          [`events.${eventId}`]: true,
        });

        batch.commit().then((response) => resolve(response));
      });
    },
    // eslint-disable-next-line no-unused-vars, object-curly-newline
    syncGames(context, { playersId, eventId, gameId, game }) {
      return new Promise((resolve) => {
        const db = firebase.firestore();
        const batch = db.batch();

        batch.update(db.collection('events').doc(eventId), {
          [`games.${gameId}`]: game.date,
        });

        Object.keys(playersId).forEach((playerId) => {
          batch.update(db.collection('players').doc(playerId), {
            [`games.${gameId}`]: playersId[playerId],
          });
        });

        batch.commit().then((response) => resolve(response));
      });
    },
  },

};
