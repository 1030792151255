<template>
  <y-sheet>

    <template #title>
      {{ title }}
    </template>

    <p
      v-if="body"
      v-html="body" />

    <template #footer>
      <button
        :class="[
          'text-sm text-white px-4 py-3 w-full rounded focus:outline-none uppercase transition',
          'bg-green-500 hover:bg-green-600 mr-1'
        ]"
        @click="confirm">
        Oui
      </button>
      <button
        :class="[
          'text-sm text-white px-4 py-3 w-full rounded focus:outline-none uppercase transition',
          'bg-red-500 hover:bg-red-600 ml-1'
        ]"
        @click="cancel">
        Non
      </button>
    </template>

  </y-sheet>
</template>

<script>
import { getCurrentInstance } from 'vue';

import YSheet from '@/UI/YSheet.vue';

export default {

  components: {
    YSheet,
  },

  props: {
    modalRef: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Êtes-vous sûr ?',
    },
    body: {
      type: String,
      required: false,
    },
    onConfirm: {
      type: Function,
    },
  },

  setup(props) {
    const internalInstance = getCurrentInstance();
    const { $emitter } = internalInstance.appContext.config.globalProperties;

    const close = () => {
      $emitter.emit('modal:close', props.modalRef);
    };

    const confirm = () => {
      props.onConfirm();
      close();
    };

    const cancel = () => {
      close();
    };

    return { confirm, cancel };
  },

};
</script>
