<template>
  <p class="mb-4 last:mb-0">
    <y-label>
      <slot />
    </y-label>
    <input
      class="w-full border px-4 py-2 rounded"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)" />
  </p>
</template>

<script>
import YLabel from '@/UI/YLabel.vue';

export default {

  components: {
    YLabel,
  },

  emits: [
    'update:modelValue',
  ],

  props: {
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
  },

};
</script>
